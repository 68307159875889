// 1. Import the initial variables
@import "bulma/sass/utilities/initial-variables";
@import "bulma/sass/utilities/functions";
// 2. Setup your Custom Colors
$primary: #63DD4B;
$secondary: white;
$primary-text: black;
$primary-invert: findColorInvert($primary);

// 3. Add new color variables to the color map.
@import "bulma/sass/utilities/derived-variables.sass";
$addColors: (
  "primaryColor":($primary, $primary-invert),
);
$colors: map-merge($colors, $addColors);
// colorize to primary
$tabs-boxed-link-active-background-color: $primary;
$tabs-boxed-link-hover-background-color: $primary;
$navbar-background-color: $primary;
$navbar-item-hover-background-color: $primary;
$body-background-color: $secondary;

@import 'bulma/bulma';
// @import 'bulma-extensions/extensions';

.button-border-color {
  border-color: $primary;
}

.is-border-box-item {
  line-height:40px;
  border-bottom: 2px solid $primary;
}

.is-header-hr {
  border: 0;
  height: 1px;
  background: black;
  background-image: linear-gradient(to right, #ccc, #333, #ccc);
}

.is-burger-style {
  color: $primary;
  align-items: center;
  align-self: center;
  align-content: center;
}

.hamburger-line {
  border: 1.2px solid !important;
}

.is-white-background {
  background-color: white !important;
  background: white !important;
}

.is-primary-background {
  background-color: $primary !important;
  background: $primary !important;
}

.is-primary-txt {
  color: $primary !important;
}

.is-default-txt {
  color: black !important;
}

.navbarHeader {
  background-color: white;
  height: 100px;
}

// imported css
@import url("https://fonts.googleapis.com/css?family=Lato|Poppins|Kaushan+Script");

.has-vertically-aligned-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

* {
  box-sizing: border-box;
}

html {
  font-family: 'Poppins', Lato, Tahoma, sans-serif;
  width: 100%;
  min-height: 100%;
  background-color: white;
}

.header-wrapper {
  position: fixed;
  width: 100%;
  // padding-bottom: 30px;
  background: white !important;
  background-color: white !important;
  z-index: 999999;
}

.hero {
  // background-image: url(https://picsum.photos/id/381/1920/1080);
  // background-image: url(./images/new-logo-image.png);
  // background: linear-gradient(rgba(31, 44, 108, 0.65), rgba(31, 44, 108, 0.65)), rgba(0, 0, 0, 0.55) url("https://picsum.photos/id/381/1920/1080") no-repeat;
  // background: linear-gradient(rgba(31, 44, 108, 0.65), rgba(31, 44, 108, 0.65)), rgba(0, 0, 0, 0.55) url(./images/new-logo-image.png) no-repeat;
  background-attachment: fixed;
  // background-size: cover;
  background-size: contain;
  color: white;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-family: 'Poppins', sans-serif;
}

.hero .title {
  font-family: 'Kaushan Script', sans-serif;
  transform: rotate(-5deg);
  font-size: 5rem;
  color: black;
}

.hero .subtitle {
  padding: 5px;
  color: black;
}

.hero .profession {
  padding-top: 50px;
  font-size: 1.7rem;
  text-transform: uppercase;
}

.hero .hero-foot {
  height: 50px;
  background: white;
  background-color: white;
  // box-shadow: 0 2px 15px rgba(0, 0, 0, 0.082);
}

@media (max-width: 599px) {
  .hero .hero-foot {
    display: none;
  }
}

.hero .hero-foot .hero-foot--wrapper {
  margin: 7px auto;
  height: 50px;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop {
  line-height: 38px;
  font-weight: 600;
  // text-transform: lowercase;
  letter-spacing: 1px;
  font-size: 1.3rem;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop ul li {
  display: inline-block;
  padding-right: 15px;
  padding-left: 15px;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop ul li.is-active a {
  border-bottom: 2px solid $primary;
  color: $primary
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop ul li a {
  color: $primary-text;
}

.hero .hero-foot .hero-foot--wrapper .hero-menu-desktop ul li a:hover {
  color: $primary-text;
  transition: all .2s ease-in-out;
  border-bottom: 2px solid $primary-text;
}

.main-content {
  padding-top: 10rem;
  color: $primary-text;
  font-family: 'Poppins', sans-serif;
}

.main-content .section-dark {
  background-color: #f0f3f5;
  padding: 60px 0px;
}

.main-content .section-dark.resume {
  height: 400px;
  padding-top: 100px;
}

@media (max-width: 599px) {
  .main-content {
    padding-top: 7rem;
  }
}

.main-content .section-dark.resume .title {
  padding: 20px;
}

.main-content .section-dark.resume button {
  border: 2px solid $primary-text;
  background: white;
  color: $primary-text;
  height: 50px;
  width: 250px;
  font-size: 1rem;
  text-transform: uppercase;
}

.main-content .section-dark.resume .fa-download {
  color: $primary-text;
}

.main-content .section-dark.my-work {
  padding: 20px;
}

@media (max-width: 599px) {
  .main-content .section-dark.my-work {
    padding: 75px 20px;
  }
}

.main-content .section-dark.my-work .work-item {
  margin-bottom: 1rem;
  -webkit-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

.main-content .section-dark.my-work .work-item:hover {
  -webkit-transform: scale(1.055) translateY(-2px);
  transform: scale(1.055) translateY(-2px);
  -webkit-box-shadow: 0 2px 25px 0 rgba(30, 30, 30, 0.1);
  box-shadow: 0 2px 25px 0 rgba(30, 30, 30, 0.1);
}

.main-content .section-light {
  padding: 30px;
}

.main-content .section-light.about {
  padding-bottom: 20px;
}

.main-content .section-light.about .is-larger {
  font-size: 1.2rem;
}

@media (max-width: 599px) {
  .main-content .section-light.about .about-links {
    text-align: center;
  }
}

.main-content .section-light.about .right-image img {
  border-radius: 11px;
  margin: 20px;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

@media (max-width: 599px) {
  .main-content .section-light.about .right-image img {
    margin: auto;
  }
}

.main-content .section-light.skills {
  padding: 100px;
}

@media (max-width: 599px) {
  .main-content .section-light.skills {
    padding: 50px 20px 40px 20px;
    padding-bottom: 20px;
  }
}

.main-content .section-light.skills h1 {
  padding: 10px;
}

.main-content .section-light.contact {
  // padding: 200px;
  background-color: whitesmoke;
}

.main-content .section-light.contact button {
  width: 100%;
  background-color: $primary-text;
  color: white;
  height: 50px;
  font-size: 1.1rem;
  text-transform: uppercase;
  font-family: 'Poppins', sans-serif;
}

@media (max-width: 599px) {
  .main-content .section-light.contact {
    padding: 60px 20px;
  }
}

.main-content .section-color.services {
  padding: 20px;
  background-color: whitesmoke;
  color: black;
}

.main-content .section-color.services .title,
.main-content .section-color.services .subtitle {
  color: black;
}

.main-content .section-color.services i {
  font-size: 3rem;
  padding: 20px;
}

.main-content .section-title {
  color: $primary-text;
  text-transform: uppercase;
  font-size: 1.8rem;
  letter-spacing: 2px;
  padding-bottom: 10px;
}

.footer {
  bottom: 0;
  width: 100vw;
  padding: 0;
  height: 200px;
  line-height: 25px;
  text-align: center;
  background: $primary-text;
  color: whitesmoke;
  padding-top: 2rem;
}

.footer a {
  padding: 0rem 1rem 1rem 1rem;
  color: lightgrey;
}

.footer a i {
  font-size: 1.5rem;
  padding: 0px 10px;
}

.footer a .nav-item {
  color: whitesmoke;
}

.fa-download {
  color: $primary-text;
}

#toTop {
  // height: 50px;
  // width: 50px;
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $primary;
  color: white;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 12px;
  padding-left: 12px;
  // border-radius: 50%;
  border-radius: 4px;
  font-size: 2rem;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
}

#toTop a {
  color: white;
}

#toTop:hover {
  background-color: white;
  color: $primary;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(245, 245, 245, 0);
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $primary-text;
}

body.preloader-site {
  overflow: hidden;
}

.preloader-wrapper {
  height: 100%;
  width: 100%;
  background: $primary-text;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
}

.preloader-wrapper .preloader {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 120px;
}

.white
{
  color:ghostwhite
}

// is header items
.is-header-text {
  color: gray !important;
  text-shadow: whitesmoke !important;
  font-weight: bold;
}

.disable-highlight {
  background-color: transparent !important;
}

.footer-custom {
  background-color: #c4c4c4;
}