/** ORIGINAL IMPLEMENTATION BUT HAD MOBILE ISSUES, BELOW WORKS PROPERLY **/
// .flip-card-container {
//     cursor: pointer;
//     height: 100%;
//     width: 100%;
//     min-height: 200px;
//     perspective: 1000;
//     position: relative;
// }
// .flip-card {
//     height: 100%;
//     width: 100%;
//     border-radius: 25px;
//     background: #F5F5F5;
//     position: absolute;
//     transform-style: preserve-3d;
//     -webkit-transform-style: preserve-3d;
//     transition: all 300ms ease-in-out;
// }
// .flip-card:hover {
//     transform: rotateY(180deg);
// }
// .flip-card .side {
//     backface-visibility: hidden;
//     -webkit-backface-visibility: hidden;
//     border-radius: 25px;
//     height: 100%;
//     width: 100%;
//     position: absolute;
//     overflow: hidden;
//     transform: rotateY(0deg);
// }
// .flip-card .back {
//     background: #63DD4B;
//     text-align: center;
//     transform: rotateY(180deg);
//      // color: #0087cc;
//     // line-height: 150px;
// }

/*https://davidwalsh.name/css-flip*/
/** NEW BEST IMPEMENTATION AVALIABLE **/
/* entire container, keeps perspective */
.flip-container {
	perspective: 1000px;
}
/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
    transform: rotateY(180deg);
}

.flip-container, .front, .back {
    cursor: pointer;
    height: 100%;
    width: 100%;
    min-height: 200px;
    perspective: 1000;
}

/* flip speed goes here */
.flipper {
    // transition: 0.6s;
    transition: all 300ms ease-in-out;
	transform-style: preserve-3d;

	position: relative;
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;

	position: absolute;
	top: 0;
    left: 0;
}

/* front pane, placed above back */
.front {
	z-index: 2;
	/* for firefox 31 */
    transform: rotateY(0deg);
    border-radius: 25px;
    background: #F5F5F5;
}

/* back, initially hidden pane */
.back {
    transform: rotateY(180deg);
    background: #63DD4B;
    text-align: center;
    border-radius: 25px;
}