/** ORIGINAL IMPLEMENTATION BUT HAD MOBILE ISSUES, BELOW WORKS PROPERLY **/
/*https://davidwalsh.name/css-flip*/
/** NEW BEST IMPEMENTATION AVALIABLE **/
/* entire container, keeps perspective */
.flip-container {
  perspective: 1000px;
}

/* flip the pane when hovered */
.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container, .front, .back {
  cursor: pointer;
  height: 100%;
  width: 100%;
  min-height: 200px;
  perspective: 1000;
}

/* flip speed goes here */
.flipper {
  transition: all 300ms ease-in-out;
  transform-style: preserve-3d;
  position: relative;
}

/* hide back of pane during swap */
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

/* front pane, placed above back */
.front {
  z-index: 2;
  /* for firefox 31 */
  transform: rotateY(0deg);
  border-radius: 25px;
  background: #F5F5F5;
}

/* back, initially hidden pane */
.back {
  transform: rotateY(180deg);
  background: #63DD4B;
  text-align: center;
  border-radius: 25px;
}

